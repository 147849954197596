<template>
  <b-card class="package">
    <div class="package__icon">
      <svg
        height="800px"
        width="800px"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 58.064 58.064"
        xml:space="preserve"
      >
        <polygon
          style="fill:#7367f0;"
          points="17.064,31.032 58.064,10.032 24.064,35.032 44.064,48.032 58.064,10.032 0,22.032 "
        />
        <polygon
          style="fill:#3e32bc;"
          points="24.064,35.032 20.127,48.032 17.064,31.032 58.064,10.032 "
        />
        <polygon
          style="fill:#ff9f43;"
          points="24.064,35.032 20.064,48.032 31.912,40.133 "
        />
      </svg>
    </div>
    <h3 class="package__name">
      {{ name }}
    </h3>
    <p class="package__caption">
      охоплення від <span>{{ coverage }}</span> переглядів
      <span
        v-b-tooltip.hover.v-primary
        class="package__planning"
        title="Приблизний прогноз, точний прогноз охоплень залежить від СPM вибраної тематики"
      >
        <i class="feather icon-help-circle" />
      </span>
    </p>
    <p class="package__price">
      {{ price }} <span>грн</span>
    </p>
    <p class="package__description">
      {{ description }}
    </p>
    <ul class="package-services">
      <li
        v-for="(item, i) in services"
        :key="i"
        class="package-services__item"
        :class="markedServices.includes(item.value) ? 'package-services__item_check' : 'package-services__item_x'"
      >
        <i
          class="feather"
          :class="markedServices.includes(item.value) ? 'icon-check' : 'icon-x'"
        />
        {{ item.text }}
      </li>
    </ul>
    <b-button
      variant="primary"
      size="md"
      href="https://t.me/teleads"
      target="_blank"
      class="package__btn"
    >
      Замовити тариф
    </b-button>
  </b-card>
</template>

<script>
import GlobalMixin from '@mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
    coverage: {
      type: String,
      default: '',
    },
    price: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    markedServices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      services: [
        {
          value: 'selection',
          text: 'Ручний підбір каналів від менеджера',
        },
        {
          value: 'plan',
          text: 'Індивідуальний план просування',
        },
        {
          value: 'notification',
          text: 'Оповіщення про графік публікацій',
        },
        {
          value: 'analysis-creatives',
          text: 'Аналіз рекламних креативів',
        },
        {
          value: 'сoordination',
          text: 'Узгодження вибраних каналів',
        },
        {
          value: 'forecast',
          text: 'Прогноз результатів кампанії',
        },
        {
          value: 'create-creatives',
          text: 'Редактура/Створення рекламних креативів',
        },
        {
          value: 'analysis-campaigns',
          text: 'Аналіз рекламної кампанії',
        },
        {
          value: 'report',
          text: 'Надання докладного звіту',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.package {
  height: 100%;
  margin-bottom: 0;

  .card-body {
    display: flex;
    flex-direction: column;
  }
}

.package__caption {
  font-weight: 500;

  span {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.package__price {
  padding: 4px 8px;
  align-self: center;
  background: var(--primary);
  border-radius: 4px;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  white-space: nowrap;

  span {
    font-weight: 400;
    font-size: 1rem;
  }
}

.package__planning {
  cursor: help;
}

.package__icon {
  width: 64px;
  height: 64px;
  margin: 0 auto 0.5em;

  svg {
    width: 100%;
    height: 100%;
  }
}

.package__name {
  font-weight: bold;
}

.package__description {
  font-weight: 500;
}

.package-services {
  padding: 0;
  margin: auto 0 1rem;
  list-style: none;
  text-align: left;
}

.package-services__item {
  display: flex;
  align-items: start;
  margin-bottom: 8px;

  .feather {
    margin-top: 4px;
    margin-right: 8px;
    font-size: 20px;
  }
}

.package-services__item_check {
  .feather {
    color: var(--green);
  }
}

.package-services__item_x {
  .feather {
    color: var(--red);
  }
}
</style>
