<template>
  <div class="packages_tariffs text-center">
    <h2>
      Виберіть ідеальний тариф під ваші цілі та задачі:
    </h2>
    <b-card-text class="mb-3">
      Послуга персонального менеджера надається в якості бонуса. Весь бюджет, вказаний в тарифі, буде спрямований на покупку рекламних інтеграцій в каталозі TeLeAds. Менеджер зв’яжеться з вами найближчим часом.
    </b-card-text>
    <div class="packages__list">
      <swiper :options="swiperOptions">
        <swiper-slide
          v-for="(item, i) in tariffs"
          :key="i"
        >
          <PackagesItem v-bind="item" />
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
        <button
          slot="button-prev"
          class="swiper-button-prev"
          type="button"
        />
        <button
          slot="button-next"
          class="swiper-button-next"
          type="button"
        />
      </swiper>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import GlobalMixin from '@mixins/GlobalMixin'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import PackagesItem from '@/views/packages/PackagesItem.vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    PackagesItem,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      tariffs: [
        {
          name: 'Початковий',
          coverage: '40 000',
          price: '10 000',
          description: 'Мінімальний тариф. Протестуйте рекламу в Telegram за доступною ціною та оцініть ефект від розміщення.',
          markedServices: [
            'selection',
            'plan',
            'notification',
            'analysis-creatives',
            'сoordination',
          ],
        },
        {
          name: 'Стандартний',
          coverage: '90 000',
          price: '25 000',
          description: 'Тариф для просування малого бізнесу, які шукають нову аудиторію та клієнтів.',
          markedServices: [
            'selection',
            'plan',
            'notification',
            'analysis-creatives',
            'сoordination',
            'forecast',
          ],
        },
        {
          name: 'Професіонал',
          coverage: '180 000',
          price: '47 000',
          description: 'Тариф для власників телеграм-каналів, які хочуть якісно та активно розвивати свій канал.',
          markedServices: [
            'selection',
            'plan',
            'notification',
            'analysis-creatives',
            'сoordination',
            'forecast',
            'create-creatives',
          ],
        },
        {
          name: 'Бізнес',
          coverage: '300 000',
          price: '75 000',
          description: 'Тариф для середнього бізнесу та власників каналів, яким швидко потрібні клієнти та цільова аудиторія',
          markedServices: [
            'selection',
            'plan',
            'notification',
            'analysis-creatives',
            'сoordination',
            'forecast',
            'create-creatives',
            'analysis-campaigns',
          ],
        },
        {
          name: 'Експерт',
          coverage: '750 000',
          price: 'від 140 000',
          description: 'Тариф для компаній та брендів, які шукають індивідуальних підхід до рекламної кампанії та максимальний результат від реклами.',
          markedServices: [
            'selection',
            'plan',
            'notification',
            'analysis-creatives',
            'сoordination',
            'forecast',
            'create-creatives',
            'analysis-campaigns',
            'report',
          ],
        },
        // {
        //   name: 'Преміум',
        //   coverage: '1 100 000',
        //   price: '270 000',
        //   description: 'Тариф для компаній та організацій, які шукають індивідуальних підхід до рекламної кампанії та максимальний результат від реклами.',
        //   markedServices: [
        //     'selection',
        //     'plan',
        //     'notification',
        //     'analysis-creatives',
        //     'сoordination',
        //     'forecast',
        //     'create-creatives',
        //     'analysis-campaigns',
        //     'report',
        //   ],
        // },
        // {
        //   name: 'Брендам',
        //   coverage: '2 200 000',
        //   price: '420 000',
        //   description: 'Тариф для великого бізнесу та брендів. Розрахований на потреби клієнтів, індивідуальне узгодження всіх процесів рекламної кампанії.',
        //   markedServices: [
        //     'selection',
        //     'plan',
        //     'notification',
        //     'analysis-creatives',
        //     'сoordination',
        //     'forecast',
        //     'create-creatives',
        //     'analysis-campaigns',
        //     'report',
        //   ],
        // },
      ],
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 8,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
}
</script>

<style lang="scss">
.packages_tariffs {
  margin-top: 3em;

  @media (max-width: 700px) {
    margin-top: 2em;
  }

  h2 {
    max-width: 640px;
    font-size: 2.4em;
    text-align: center;
    display: block;
    margin: 0 auto 1em;
  }

  @media (max-width: 700px) {
    h2 {
      font-size: 1.8em;
    }
  }

  .card-text {
    max-width: 880px;
    margin-inline: auto;
    font-size: 1.28em;
    font-weight: 500;
    line-height: 1.5;
    color: #5e5873;
  }

  @media (max-width: 700px) {
    .card-text {
      font-size: 1em;
    }
  }

  .packages__list {
    padding-bottom: 36px;
    overflow: hidden;
  }

  .swiper-container {
    overflow: visible;
  }

  @media (min-width: 1560px) {
    .swiper-wrapper {
      justify-content: center;
    }
  }

  .swiper-slide {
    max-width: 240px;
    width: 100%;
    height: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 0;
    border: none;
    border-radius: 0;
    background: transparent;
    outline: none;
    color: var(--primary);
  }

  @media (max-width: 700px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-pagination {
    bottom: -36px;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  .swiper-pagination-bullet-active {
    background: var(--primary);
  }
}
</style>
