<template>
  <div class="home_page">
    <div class="block_1">
      <b-row class="align-items-center">
        <b-col
          cols="12"
          lg="6"
        >
          <div class="left_side">
            <h1 class="h1">
              Нативні розміщення в Телеграм: <b-badge variant="warning">
                перевірені вручну
              </b-badge> канали
            </h1>
            <h3>Ми допомагаємо знайти цільову аудиторію в Telegram та запускати ефективні інтеграції</h3>
            <b-row>
              <b-col
                cols="12"
                sm="4"
              >
                <b-card class="text-center">
                  <b-avatar
                    class="mb-1"
                    variant="light-warning"
                    size="45"
                  >
                    <feather-icon
                      size="21"
                      icon="UserCheckIcon"
                    />
                  </b-avatar>
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ $options.filters.numberFormat(home.all.u) }}
                    </h2>
                    <p>
                      <b-badge variant="success">
                        + <b>{{ $options.filters.numberFormat(home.m.u) }}</b> за місяць
                      </b-badge>
                    </p>
                    <span>нових користувачів на платформі</span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-card class="text-center">
                  <b-avatar
                    class="mb-1"
                    variant="light-warning"
                    size="45"
                  >
                    <feather-icon
                      size="21"
                      icon="ListIcon"
                    />
                  </b-avatar>
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ $options.filters.numberFormat(home.all.c) }}
                    </h2>
                    <p>
                      <b-badge variant="success">
                        + <b>{{ $options.filters.numberFormat(home.m.c) }}</b> за місяць
                      </b-badge>
                    </p>
                    <span>каналів, перевірених вручну</span>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-card class="text-center">
                  <b-avatar
                    class="mb-1"
                    variant="light-warning"
                    size="45"
                  >
                    <feather-icon
                      size="21"
                      icon="ShoppingBagIcon"
                    />
                  </b-avatar>
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ $options.filters.numberFormat(home.all.t) }}
                    </h2>
                    <p>
                      <b-badge variant="success">
                        + <b>{{ $options.filters.numberFormat(home.m.t) }}</b> за місяць
                      </b-badge>
                    </p>
                    <span>успішно реалізованих замовлень</span>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <div class="btn_c">
              <b-button
                v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
                variant="primary"
                size="lg"
                :to="`/catalog/channels`"
                class="mb-2"
              >
                Купити розміщення
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card>
            <div class="main_video">
              <video
                autoplay="autoplay"
                height="100%"
                loop="loop"
                muted="muted"
                preload="auto"
                width="100%"
              >
                <source
                  src="/video/home-new.mp4"
                  type="video/mp4"
                >
                Your browser does not support the video tag.
              </video>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div class="block_2">
      <b-row>
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Як працює платформа?
          </h3>
        </b-col>
        <b-col
          cols="12"
          md="7"
          class="d-none d-md-block"
        >
          <b-img
            src="/icons/bgWhy.svg"
            alt="bgWhy"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <app-timeline>
            <app-timeline-item icon="UserPlusIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Реєструйте акаунт</h3>
              </div>
            </app-timeline-item>
            <app-timeline-item icon="ListIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Виберіть відповідні канали
                  у каталозі</h3>
              </div>
            </app-timeline-item>
            <app-timeline-item icon="DollarSignIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Поповніть баланс</h3>
              </div>
            </app-timeline-item>
            <app-timeline-item icon="BriefcaseIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Створіть
                  розміщення
                  з картинкою та посиланням</h3>
              </div>
            </app-timeline-item>
            <app-timeline-item icon="CheckCircleIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Власник каналу
                  публікує пост
                  і надсилає посилання
                  на перевірку</h3>
              </div>
            </app-timeline-item>
            <app-timeline-item icon="FileTextIcon">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>Ви можете отримати звіт за результатами розміщення</h3>
              </div>
            </app-timeline-item>
          </app-timeline>
          <b-button
            v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
            variant="warning"
            size="lg"
            :to="`/catalog/channels`"
            class="mt-3"
          >
            Створити проект
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div class="block_3">
      <h3>
        Як купити інтеграцію у Телеграм з TeLeAds
      </h3>
      <b-row>
        <b-col
          cols="12"
          md="1"
        />
        <b-col
          cols="12"
          md="5"
        >
          <b-card>
            <div class="top">
              <div class="left">
                <b-badge variant="secondary">
                  ручний процес
                </b-badge>
                <b-badge variant="secondary">
                  нема гарантій
                </b-badge>
              </div>
              <div class="right">
                😰
              </div>
            </div>
            <h4>Самостійне розташування безпосередньо</h4>
            <app-timeline>
              <app-timeline-item
                icon="ClockIcon"
                variant="secondary"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Знайти каталог Телеграм-каналів через Google</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="ClockIcon"
                variant="secondary"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Перевірити канали на живих передплатників</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="ClockIcon"
                variant="secondary"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Написати кожному адміну, щоб дізнатися вартість інтеграції</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="ClockIcon"
                variant="secondary"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Сплатити розміщення кожному адміну</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="ClockIcon"
                variant="secondary"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Написати пости для інтеграції</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="ClockIcon"
                variant="secondary"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Надіслати пост кожному адміну</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="ClockIcon"
                variant="secondary"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Контролювати вихід посту у кожному каналі</h5>
                </div>
              </app-timeline-item>
            </app-timeline>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <b-card class="success bg-primary">
            <div class="top">
              <div class="left">
                <b-badge variant="success">
                  автоматично
                </b-badge>
                <b-badge variant="success">
                  гарантія розміщення
                </b-badge>
              </div>
              <div class="right">
                😍
              </div>
            </div>
            <h4>Автоматичне розміщення з TeLeAds</h4>
            <app-timeline>
              <app-timeline-item
                icon="SunIcon"
                variant="success"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Зареєструйтесь на платформі</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="SunIcon"
                variant="success"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Виберіть відповідні канали з каталогу в один клік або купіть розміщення під ключ</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="SunIcon"
                variant="success"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Поповніть баланс зручним способом</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="SunIcon"
                variant="success"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Створіть інтеграцію</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="SunIcon"
                variant="success"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Запустіть нативну інтеграцію</h5>
                </div>
              </app-timeline-item>
              <app-timeline-item
                icon="SunIcon"
                variant="success"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>Отримайте оповіщення про кожне розміщення</h5>
                </div>
              </app-timeline-item>
            </app-timeline>
            <b-button
              v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
              variant="warning"
              size="lg"
              :to="`/catalog/channels`"
              class="mt-2"
            >
              Створити проект
            </b-button>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="1"
        />
      </b-row>
    </div>

    <div class="block_tariffs">
      <PackagesTariffs />
      <b-row>
        <b-col
          cols="12"
          class="p-2 "
        >
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="warning"
              size="lg"
              :to="`/packages`"
            >
              Тарифні пропозиції
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="block_4">
      <b-row>
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Гарячі пропозиції з <b-badge variant="danger">
              реальними
            </b-badge> знижками
          </h3>
        </b-col>
        <b-col
          v-if="products && products.length"
          cols="12"
          class="ecommerce-application"
        >
          <section class="grid-view">
            <Card
              v-for="product in products"
              :key="product.id"
              :product="product"
              :hot="true"
            />
          </section>
        </b-col>
        <b-col
          cols="12"
          class="p-2 "
        >
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
              variant="warning"
              size="lg"
              :to="`/catalog/hot_channels`"
            >
              Показати повний список
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="block_5">
      <h3>
        Біржа реклами в Телеграм TeLeAds: <br>відгуки клієнтів та власників каналів
      </h3>
      <b-row>
        <b-col
          cols="12"
          md="1"
        />
        <b-col
          cols="12"
          md="10"
        >
          <b-carousel
            id="carousel-interval"
            no-hover-pause
            controls
            indicators
            :interval="5000"
          >
            <b-carousel-slide
              v-for="(block, index) in getChunkMessages"
              :key="index"
            >
              <template #img>
                <b-row>
                  <b-col
                    v-for="review in block"
                    :key="review.id"
                    cols="12"
                    md="6"
                  >
                    <b-card>
                      <h6 class="font-weight-bolder mb-25">
                        {{ review.title }}
                      </h6>
                      <b-card-text>
                        {{ review.created_at }}
                      </b-card-text>
                      <b-card-text>
                        {{ review.message }}
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </b-col>
        <b-col
          cols="12"
          md="1"
        />
        <b-col
          cols="12"
          class="p-2 "
        >
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
              variant="primary"
              size="lg"
              :to="`/reviews`"
            >
              Показати всі відгуки
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="block_6">
      <b-row class="text-center">
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Потрібна консультація чи залишилися питання щодо просування та розміщення реклами в Telegram?
          </h3>
          <h4 class="mb-3">
            Ми з радістю відповімо! Задайте питання нам особисто ⤵️
          </h4>
        </b-col>
        <b-col md="4">
          <b-card class="shadow-none py-1">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MessageCircleIcon"
                size="18"
              />
            </b-avatar>
            <a
              href="https://t.me/TeLeAds_Support_Bot"
              target="_blank"
            >
              @TeLeAds_Support_Bot
            </a>
            <span class="text-body">Технічна підтримка</span>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="shadow-none py-1">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="SendIcon"
                size="18"
              />
            </b-avatar>
            <a
              href="https://t.me/teleads"
              target="_blank"
            >
              @TeLeAds
            </a>
            <span class="text-body">Менеджер</span>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="shadow-none py-1">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <a
              href="mailto:support@teleads.com.ua"
              target="_blank"
            >
              support@teleads.com.ua
            </a>
            <span class="text-body">Написати на пошту</span>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import GlobalMixin from '@mixins/GlobalMixin'
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Card from '@/views/catalog/Card'
import PackagesTariffs from '@/views/packages/PackagesTariffs.vue'
import _ from 'lodash'
// import router from '@/router'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    Card,
    PackagesTariffs,
    BCarousel,
    BCarouselSlide,
  },
  mixins: [GlobalMixin],
  metaInfo: {
    title: 'Біржа реклами в Телеграм | TeLeAds',
    meta: [
      { vmid: 'description', name: 'description', content: 'Біржа реклами в telegram-каналах ➡️ Великий вибір вручну перевірених каналів ✔️ Доступні ціни ✔️ Швидке та якісне розміщення ✈️ TeLeAds' },
    ],
  },
  data() {
    return {
      home: {
        all: {
          u: 0,
          c: 0,
          t: 0,
        },
        m: {
          u: 0,
          c: 0,
          t: 0,
        },
      },
      products: [],
      messages: [],
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    getChunkMessages() {
      return _.chunk(this.messages, 2)
    },
  },
  created() {
    this.fetchHome()
    this.fetchHotChannels()
    this.fetchReviews()
  },
  methods: {
    fetchHotChannels() {
      this.$http.get('/api/promo/products/', {
        params: {
          sorting: 'id-desc',
          per_page: 3,
          page: 1,
          filter: 'discounts:1;',
        },
      })
        .then(response => {
          this.products = response.data.data
        })
    },
    fetchReviews() {
      this.$http.get('/api/promo/messages/', {
        params: {
          per_page: 10,
        },
      })
        .then(response => {
          this.messages = response.data.data
        })
    },
    fetchHome() {
      this.$http.get('/api/promo/homepage/')
        .then(response => {
          this.home = response.data.data
        })
    },
  },
  setup() {
    const { canViewVerticalNavMenuLink } = useAclUtils()
    return {
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style lang="scss">
.home_page {
  .content-header {
    display: none;
  }

  .block_1 {
    h1 {
      margin:0 0 1em;
      font-size: 3em;
    }

    h3 {
      margin:0 0 2em;
    }

    @media (max-width: 700px) {
      h1 {
        font-size: 2em;
      }
    }
  }

  .block_2 {
    background: var(--primary);
    margin-top:2em;
    border-radius: 0.428rem;
    padding: 3em;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin:0 0 3em;

      h3 {
        font-size: 3em;
        color: #fff;
        font-weight: bold;
      }
    }

    h3 {
      color:#fff;
    }

    @media (max-width: 700px) {
      padding: 1em;

      .title {
        h3 {
          font-size: 2em;
        }
      }
    }
  }

  .block_3 {
    padding: 3em 0;

    h3 {
      font-size: 3em;
      text-align: center;
      display: block;
      margin: 2em 0;
      font-weight: bold;
    }

    .card {
      &.success h1,
      &.success h2,
      &.success h3,
      &.success h4,
      &.success h5,
      &.success h6,
      &.success span,
      &.success p {
        color: #fff !important;
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          gap:1em;
        }

        .right {
          font-size: 2em;
        }
      }

      h4 {
        font-size: 2em;
        font-weight: bold;
        margin: 0 0 2em;
        display: block;
      }
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 2em;
      }
    }
  }

  .block_tariffs {
    margin-bottom: 4em;
  }

  .block_4 {
    background: var(--primary);
    margin-top:2em;
    border-radius: 0.428rem;
    padding: 3em;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin:0 0 3em;

      h3 {
        font-size: 3em;
        color: #fff;
        font-weight: bold;
      }
    }

    @media (max-width: 700px) {
      padding: 1em;

      .title {
        margin:0 0 1em;

        h3 {
          font-size: 2em;
        }
      }
    }
  }

  .block_5 {
    padding: 3em 0;

    h3 {
      font-size: 3em;
      text-align: center;
      display: block;
      margin: 2em 0;
      font-weight: bold;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 2em;
      }
    }
  }

  .block_6 {
    padding-bottom: 3em;

    h3 {
      max-width: 920px;
      font-size: 3em;
      text-align: center;
      display: block;
      margin: 1em auto;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.5em;
      }
    }

    h4 {
      font-size: 1.5rem;
      line-height: 1.5;
    }

    @media (max-width: 700px) {
      h4 {
        font-size: 1rem;
      }
    }

    .card {
      height: 100%;
      margin-bottom: 0;

      .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      a {
        margin-bottom: 0.5rem;
        font-weight: 500;
        font-size: 1.286rem;
        line-height: 1.2;
        color: #5e5873;
        word-break: break-all;
      }
    }

    @media (max-width: 700px) {
      .card {
        height: auto;
        margin-bottom: 2rem;
      }
    }
  }
}

@import "~@core/scss/base/pages/app-ecommerce.scss";
//@import "https://telega.in/assets/home/application-0aa30cd3fcd937f1f1f57949a12b769010c73736d5e4584cb9ac1cc240f4d635.css";
</style>
